import React, { memo, cloneElement, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'

import { SptImagemFeedback } from '@sponte/lib-components/dist/molecules/ImagemFeedback'

import { useQueryUsuariosControllerMinhasPermissoes } from 'api/educacao'

import { useAuth } from './auth'

function usePermissionsHook() {
  const { eu } = useAuth()

  const { data } = useQueryUsuariosControllerMinhasPermissoes({
    enabled: !!eu
  })

  const resources = useMemo(() => {
    return getSafe(data, 'itens', [])
  }, [data])

  const deletable = (resource) => !!getSafe(resource, 'acoes', []).find((acao) => acao === 'EXCLUIR')
  const editable = (resource) => !!getSafe(resource, 'acoes', []).find((acao) => acao === 'EDITAR')
  const readable = (resource) => !!getSafe(resource, 'acoes', []).find((acao) => acao === 'LER')
  const writeable = (resource) => !!getSafe(resource, 'acoes', []).find((acao) => acao === 'INCLUIR')

  return {
    resources,
    deletable,
    editable,
    readable,
    writeable
  }
}

export const [PermissionsProvider, usePermissions] = createContainer(usePermissionsHook)

export const usePermission = (name) => {
  const { resources, deletable, editable, readable, writeable } = usePermissions()

  const resource = useMemo(() => {
    return resources.find((item) => item.chave === name)
  }, [name, resources])

  const deletableFeature = name ? deletable(resource) : true
  const editableFeature = name ? editable(resource) : true
  const readableFeature = name ? readable(resource) : true
  const writeableFeature = name ? writeable(resource) : true

  return { deletableFeature, editableFeature, readableFeature, writeableFeature }
}

const FallbackDefault = memo(() => {
  const { t } = useTranslation()

  return <SptImagemFeedback information={t('geral:feedbacks.voceNaoTemAcessoEsseRecurso')} />
})

export const Permission = memo(({ name, fallback: Fallback = FallbackDefault, children, ...props }) => {
  const { deletableFeature, editableFeature, readableFeature, writeableFeature } = usePermission(name)

  if (typeof children === 'function') {
    return children({ deletableFeature, editableFeature, readableFeature, writeableFeature, ...props })
  }

  return readableFeature ? cloneElement(children, props) : <Fallback /> || null
})

export const withPermission = (name, Comp, fallback) => (props) => {
  return (
    <Permission name={name} fallback={fallback}>
      <Comp {...props} />
    </Permission>
  )
}
