import React, { memo, cloneElement, useMemo } from 'react'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'

import { useReducer } from '@sponte/lib-utils/src/hooks/useReducer'

import { FEATURE_TOGGLES } from 'config'

// eslint-disable-next-line import/no-unresolved
import { useQueryTenantsControllerMeusModulosFeatures } from 'api/educacao'

import { useAuth } from './auth'
import { withPermission } from './permissions'

const _add = (list, item) => [...list, item]
const _remove = (list, item) => list.filter((i) => i !== item)
const _toggle = (list, item) => (list.includes(item) ? _remove(list, item) : _add(list, item))

function arrayReducer(state, { type, payload }) {
  switch (type) {
    case 'ADD':
      return _add(state, payload)
    case 'REMOVE':
      return _remove(state, payload)
    case 'TOGGLE':
      return _toggle(state, payload)
    default:
      return state
  }
}

function useFeatureTogglesHook() {
  const { eu } = useAuth()
  const { data: features } = useQueryTenantsControllerMeusModulosFeatures({ enabled: !!eu })

  const initialValues = useMemo(() => {
    const featuresName = getSafe(features, 'itens', [])
      .map((a) => a.modulosTenantFeatures.map((b) => b.chaveFeature))
      .flat()
    const modulosName = getSafe(features, 'itens', [])
      .map((a) => a.chaveModulo)
      .flat()
    const values = modulosName.concat(featuresName)

    return values.concat(FEATURE_TOGGLES.filter((feature) => feature !== 'NONE' && !values.includes(feature)))
  }, [features])

  const [featureToggles, dispatch] = useReducer(arrayReducer, initialValues)

  const isEnabled = (name) => initialValues.includes(name)
  const toggle = (name) => dispatch('TOGGLE', name)
  const enable = (name) => dispatch('ADD', name)
  const disable = (name) => dispatch('REMOVE', name)

  return {
    featureToggles,
    isEnabled,
    toggle,
    enable,
    disable
  }
}

export const [FeatureTogglesProvider, useFeatureToggles] = createContainer(useFeatureTogglesHook)

export const useFeatureToggle = (name) => {
  const { isEnabled, toggle, enable, disable } = useFeatureToggles()

  const enabledFeature = isEnabled(name)
  const toggleFeature = () => toggle(name)
  const enableFeature = () => enable(name)
  const disableFeature = () => disable(name)

  return [enabledFeature, toggleFeature, enableFeature, disableFeature]
}

export const FeatureToggle = memo(({ name, fallback, children, ...props }) => {
  const [enabled, toggle, enable, disable] = useFeatureToggle(name)

  if (typeof children === 'function') {
    return children({ enabled, toggle, enable, disable, ...props })
  }

  return enabled ? cloneElement(children, props) : fallback || null
})

export const withFeatureToggle = (name, Comp, fallback) => (props) => {
  return (
    <FeatureToggle name={name} fallback={fallback}>
      <Comp {...props} />
    </FeatureToggle>
  )
}

export const withFeatureTogglePermission = (name, comp) => {
  return withFeatureToggle(name, withPermission(name, comp))
}
